var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('AgGrid',{ref:"UserListGrid",attrs:{"grid":_vm.aggrid},on:{"LoadAll":_vm.LoadAll},scopedSlots:_vm._u([{key:"lftbtn",fn:function(){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Create a New User'),expression:"'Create a New User'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.CreateNewUser()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("New User")])],1)]},proxy:true}])})],1),_c('b-modal',{ref:"CreateUser",attrs:{"id":"CreateUserModal","title":"New User","size":"lg","centered":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div')]},proxy:true}])},[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Save","back-button-text":"Previous"},on:{"on-complete":_vm.SaveNewUser}},[_c('tab-content',{attrs:{"title":"User Detail"}},[_c('validation-observer',{ref:"headerRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"User Name","rules":"required|max:95|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("User Name:")])])]),_c('b-form-input',{attrs:{"id":"NewUserUserName","type":"email","state":errors.length > 0 ? false:null,"placeholder":"User Name"},model:{value:(_vm.NewUser.UserName),callback:function ($$v) {_vm.$set(_vm.NewUser, "UserName", $$v)},expression:"NewUser.UserName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","rules":"required|max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Password:")])])]),_c('b-form-input',{attrs:{"id":"NewUserPassword","type":"password","state":errors.length > 0 ? false:null,"placeholder":"Password"},model:{value:(_vm.NewUser.Password),callback:function ($$v) {_vm.$set(_vm.NewUser, "Password", $$v)},expression:"NewUser.Password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Email:")])])]),_c('b-form-input',{attrs:{"id":"NewUserEmail","type":"email","disabled":"","state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.NewUser.UserName),callback:function ($$v) {_vm.$set(_vm.NewUser, "UserName", $$v)},expression:"NewUser.UserName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Role:")])])]),_c('v-select',{attrs:{"id":"RoleOption","options":_vm.RoleOptions,"label":"text","placeholder":"Select the role"},model:{value:(_vm.NewUser.Role),callback:function ($$v) {_vm.$set(_vm.NewUser, "Role", $$v)},expression:"NewUser.Role"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("First Name:")])])]),_c('b-form-input',{attrs:{"id":"NewUserFirstName","state":errors.length > 0 ? false:null,"placeholder":"First Name"},model:{value:(_vm.NewUser.FirstName),callback:function ($$v) {_vm.$set(_vm.NewUser, "FirstName", $$v)},expression:"NewUser.FirstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Last Name:")])])]),_c('b-form-input',{attrs:{"id":"NewUserLastName","state":errors.length > 0 ? false:null,"placeholder":"Last Name"},model:{value:(_vm.NewUser.LastName),callback:function ($$v) {_vm.$set(_vm.NewUser, "LastName", $$v)},expression:"NewUser.LastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }